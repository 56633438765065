import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
	}

	navigate({params: {path}}) {
		if (!path) {
			console.log("no value");
			return;
		}

		window.location = path;
	}
}
