import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from '../js/use-click-outside';

export default class extends Controller {
	static targets = ["dialog", "trigger"];

	static values = {
		triggerOnObserve: Boolean,
		closeOnClickOutside: Boolean,
	};

	connect() {
		if (this.closeOnClickOutsideValue) {
			useClickOutside(this);
		}

		if (this.triggerOnObserveValue && this.triggerTarget) {
			const observer = new IntersectionObserver((entries, observer) => {
				if (entries[0].isIntersecting) {
					this.open();
					observer.disconnect();
				}

			})
			observer.observe(this.triggerTarget);
		}
	}

	open() {
		this.dialogTarget.showModal();
		const modal = this.dialogTarget.querySelector(".modal");
		if (modal && modal.classList.contains("fade")) {
			modal.classList.add("show");
		}
	}

	close() {
		this.dialogTarget.close();
	}

	clickOutside(event) {
		if (event.target !== this.dialogTarget) {
			return;
		}

		if (!this.#isClickInElement(event, this.dialogTarget)) {
			this.dialogTarget.close();
		}
	}

	checkCustomBoundingRect(event) {
		return this.#isClickInElement(event, this.dialogTarget);
	}

	// Dialog elements take up the entire screen, so this is a "hack" to check actual click location.
	#isClickInElement(event, element) {
		const rect = element.getBoundingClientRect();
		return (
			rect.top <= event.clientY &&
			event.clientY <= rect.top + rect.height &&
			rect.left <= event.clientX &&
			event.clientX <= rect.left + rect.width
		);
	}

}
