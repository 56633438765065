import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = {
		text: String
	}

	id = "" + Math.round(Math.random() * 1000000);

	connect() {
		this.attachListeners();
	}

	attachListeners() {
		this.element.addEventListener("mouseover", () => {
			this.showTooltip();
		})
		this.element.addEventListener("mouseleave", () => {
			this.hideTooltip();
		})
	}

	showTooltip() {
		const existing = document.getElementById(this.id);
		if (existing) {
			return;
		}

		const tooltipEl = document.createElement("div");
		tooltipEl.classList.add("py-1", "px-2", "position-absolute", "bg-black", "text-white", "rounded", "text-center");
		tooltipEl.style.maxWidth = "200px";
		tooltipEl.innerText = this.textValue;
		tooltipEl.id = this.id;
		document.lastChild.appendChild(tooltipEl)
		this.position(tooltipEl);
	}

	hideTooltip() {
		const existing = document.getElementById(this.id);
		if (!existing) {
			return;
		}

		existing.remove();
	}

	position(el, pos = "top") {
		const target = this.element.getBoundingClientRect();
		const { width, height } = el.getBoundingClientRect();
		const dist = 5;

		let left = 0;
		let top = 0;
		switch (pos) {
			case 'top':
				left = target.x + target.width / 2 - width / 2;
				top = target.top - height - dist;
				break;
			case 'bottom':
				left = target.x + target.width/2 - width/2;
				top = target.bottom + dist;
				break;
			case 'left':
				left = target.left - width - dist;
				top = target.top + target.height/2 - height/2;
				break;
			case 'right':
				left = target.right + dist;
				top = target.top + target.height/2 - height/2;
		}

		el.style.left = `${left}px`;
		el.style.top = `${top}px`;
		el.style.zIndex = 2000;
	}
}
