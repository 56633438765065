import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from '../js/use-click-outside';

export default class extends Controller {
	static targets = ["content", "trigger"];

	static values = {
		width: String
	}

	connect() {
		let targets = [this.contentTarget];
		if (this.triggerTarget) {
			targets.push(this.triggerTarget);
		}
		useClickOutside(this, {elements: targets});

		const resizeTarget = this.getResizeTarget();
		if (resizeTarget === "") {
			return;
		}

		window.addEventListener("resize", () => this.resize(resizeTarget));
	}

	getResizeTarget() {
		if (this.widthValue === null || this.widthValue === "") {
			return "";
		}

		if (String(this.widthValue).startsWith("match:")) {
			const [match, target] = String(this.widthValue).split(":", 2);
			if (!target) {
				return "";
			}

			if (target === "trigger") {
				return "trigger";
			}

			console.error(`popover: unsupported resize target: ${target}`)
		}

		return "";
	}

	resize(target) {
		if (target === "trigger") {
			const triggerRect = this.triggerTarget.getBoundingClientRect();

			this.contentTarget.style.width = `${triggerRect.width}px`;
			this.reposition();
		}
	}

	open() {
		this.contentTarget?.classList?.remove('d-none');
		this.resize(this.getResizeTarget());
	}

	reposition() {
		const triggerRect = this.triggerTarget.getBoundingClientRect();
		const contentRect = this.contentTarget.getBoundingClientRect();
		this.contentTarget.style.translate = `${-(contentRect.width - triggerRect.width)/2}px 0`;
	}

	close() {
		this.contentTarget?.classList?.add('d-none');
	}

	clickOutside() {
		this.close();
	}
}
