import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['total', 'price', 'quantity'];

	connect() {
		this.totalTarget.value = '0';
		this.calculate();
	}

	observeChildren() {
		// If number of price or quantity targets change, recalculate total.
		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				// Check if there is a "data-calculate-total-target" attribute in the mutation.
				const nodeList = mutation.addedNodes.length > 0 ? mutation.addedNodes : mutation.removedNodes;
				const matches = [].filter.call(nodeList, (node) => {
					if (!node.querySelector) {
						return false;
					}

					if (node.querySelector('[data-calculate-total-target]')) {
						return true;
					}

					return false;
				});

				if (matches.length > 0) {
					this.calculate();
				}
			}
		});

		observer.observe(this.element, { childList: true, subtree: true });
	}

	calculate() {
		let total = 0;

		this.priceTargets.forEach((price, index) => {
			console.log(price)
			let amount = Number.parseFloat(price.value.replace(",", "."))
			if (price.value && this.quantityTargets[index].value) {
				total += 0 + amount * this.quantityTargets[index].value;
			}
		});

		this.totalTarget.value = '' + total;
	}
}
