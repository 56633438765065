const defaultOptions = {
	events: ["click", "touchend"],
};

export const useClickOutside = (controller, options = {}) => {
	const { events } = Object.assign({}, defaultOptions, options);

	const onEvent = (event) => {
		const targetElements = options?.elements || [controller.element];

		let hit = false;
		targetElements.forEach(target => {
			if (target.contains(event.target)) {
				hit = true;
				return;
			}
		});

		if (controller.checkCustomBoundingRect) {
			hit = controller.checkCustomBoundingRect(event);
		}

		if (hit) {
			return;
		}

		if (controller.clickOutside) {
			controller.clickOutside(event);
		}
	};

	const observe = () => {
		events?.forEach(event => {
			window.addEventListener(event, onEvent, true);
		});
	};

	const unobserve = () => {
		events?.forEach(event => {
			window.removeEventListener(event, onEvent, true);
		});
	};

	const controllerDisconnect = controller.disconnect.bind(controller);

	Object.assign(controller, {
		disconnect() {
			unobserve();
			controllerDisconnect();
		}
	});

	observe();

	return [observe, unobserve];
}
